:root {
    --Bluegrotto:#0091E7;
    --Royalblue:#005BEA;
    --blue: #0b72d9;
    --whitesmoke: #f5f5f5;
    --babyblue: #9bd5f2;
    --mettleblack: #494949;
    --black: #2a2929;
    --jetblack: #111010;
    --icon-height:40;
    --icon-width: 40;
    
  }


.mapcontainer {
    transition: all 0.3s ease;
}




.mapboxgl-ctrl-group.mapboxgl-ctrl{
    outline: none;
    position: relative;
    width: fit-content;
    /* margin-bottom: 10px; */
    /* opacity: 0.8; */
    
    background-color:var(--jetblack);
    transition: background 1s;
}
.mapboxgl-ctrl-top-right{
    position: relative;
    margin-top: 60px;
   
 } 
 .mapboxgl-ctrl-icon{
    position:relative;
    height: 40px !important;
    width: 40px !important;
    border-radius: 20px !important;
    background-color: #2f3235 !important;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    background-position: center;
}


.layer-control-button{
    background: url('../../SVG/layerControl.svg');
} 
.layer-control-button:active {
    opacity: 10;
    transition: all 0.8s;
    background-size: 100%; /* Increasing the size of the background image */
    transition: background 0s; 
  }
.layer-control-button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 1ms
}
.anlysiswindow-control-button{
    background: url('../../SVG/Analysis.svg');

} 
.save-feture-form-control-button{
    background: url('../../SVG/Save.svg');
} 

.user-project-control-button{
    background: url('../../SVG/Mapping.svg');
} 

.mapboxgl-ctrl-bottom-right{
    position: absolute;
}

.draw-polygon-button{
    background: url('../../SVG/pencilDraw.svg');
    transition: all 0.01s ease;
    height: 0;
} 

.mapbox-gl-draw_polygon.active{
    color: var(--babyblue);
    transition: all 0.01s ease;
}
.draw-point-button{
    background: url('../../SVG/point.svg');
} 
.mapboxgl-ctrl-icon.delete-geom-button{
    background: url('../../SVG/trash.svg');
} 

.mapboxgl-ctrl {
    clear: both;
    pointer-events: auto;
    transform: translate(0);
}
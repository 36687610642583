
.drag-drop {
    background: #0d0d0d;
    border: 2px solid var(--border-color);
    border-radius: 8px;
  }
  .document-uploader {
    border: 2px dashed #373739;
    background-color: #333535;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
  
    &.active {
      border-color: #17e883;
    }
  
    .upload-info {
      display: flex;
      word-wrap: normal;
      align-items: center;
      margin-bottom: 1rem;
  
      svg {
        font-size: 36px;
        margin-right: 1rem;
      }
  
      div {
        p {
          margin: 0;
          font-size: 16px;
        }
  
        p:first-child {
          font-weight: bold;
        }
      }
    }
  
    .file-list {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      width: 100%;
      height: 30vh;
      word-wrap: break-word;
      &__container {
        width: 100%;
        height: 100%;
        overflow: auto;
        word-wrap: break-word;
      }
    }
  
    .file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.1rem;
      border: 1px solid var(--border-color);
      border-radius: 8px;
  
      .file-info {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        flex: 1;
  
        p {
          margin: 0;
          font-size: 14px;
          color: #f8f8fb;
        }
      }
  
      .file-actions {
        cursor: pointer;
  
        svg {
          font-size: 18px;
          color: #ded5d5;
        }
  
        &:hover {
          svg {
            color: rgb(247, 77, 77);
          }
        }
      }
    }
  
    .browse-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      cursor: pointer;
      background-color:  rgb(56, 142, 235);
      &:hover {
        background-color:  rgb(56, 142, 235,0.5);
      }
    }
  
    .success-file {
      display: flex;
      align-items: center;
      color: #17e883;
  
      p {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }
    }
  
    input[type="file"] {
      display: none;
    }
  }